import { env } from '@/constants/env';
import { Socket, io } from 'socket.io-client';
import * as Sentry from '@sentry/vue';

// Default url needed for unit test because vitest doesn't inject this var
const url = new URL(env.VITE_APP_URL_API || 'https://dev.notesauditor.ai/anexo');
let socketURL = url.hostname;
if (url.port) {
  socketURL = `${socketURL}:${url.port}`;
}

const socketProtocol = socketURL.includes('localhost') ? 'ws' : 'wss';

export let socket: Socket | null;

export const socketConnectWithAuth = (token: any) => {
  socket = io(`${socketProtocol}://${socketURL}`, {
    path: '/anexo/ws/socket.io',
    auth: token,
    transports: ['websocket'],
  });
  socket.on('connect_error', (err: any) => {
    console.log(`WS: connect_error due to ${err.message}`, socketURL);
    Sentry.captureException(err);
  });
  socket.on('connect', () => {
    console.log('WS: connected!', socketURL);
  });
  socket.on('disconnect', () => {
    console.log('WS: disconnected', socketURL);
  });
};

export const socketUpdateToken = (token: any) => {
  if (!socket) return;
  socket.auth = token;

  /** reconnect to socket
   * this way we don't lose existing listeners */
  socket.disconnect().connect();
};

export const socketDisconnect = () => socket?.disconnect();
